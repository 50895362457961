<script>
import formDataBase from "./formDataBase";
const Swal = require("sweetalert2");

export default {
  data() {
    return {
      worker: {
        searching: false,
        imputSearchWorker: null,
        stateErrorWorker: null,
        about: null,
      },
      formDataBase: formDataBase,
      newForm: {},
      hasForm: false,
      newSeguimientoForm: {},
      accidentes: [],
      accidenteActivo: {
        data: [],
        isActive: null,
        seguimientoActivo: {
          data: [],
          isActive: null,
        },
      },
      addNewSeguimiento: {
        formData: {},
        isActive: null,
      },
    };
  },
  methods: {
    initNewForm() {
      this.newForm = {
        fechaDelAccidente: null,
        tipoDeEvento: null,
        lugarDelAccidente: null,
        descripcion: null,
        peligro: null,

        tipoDeEventoEspecifico: null,
        tipoDeLesion: null,
        agenteDelAccidente: null,
        parteDelCuerpoAfectada: null,
        accidenteInvestigado: null,
        investigacionEnviadaALaARL: null,
        causasInmediatas: null,
        causasBasicas: null,
        accionesAImplementar: null,
        seguimientosDeAccidentes: [],
      };
    },
    searchingWorker() {
      // tener presente que puede existir un dato de accidente investiado previo
      this.worker.searching = true;

      this.accidenteActivo.data = [];

      this.$store
        .dispatch("get", {
          path: `Persona/GetPersonaCC/${this.worker.imputSearchWorker}`,
        })
        .then((result) => {
          return new Promise((resolve, reject) => {
            result = result.traceId ? null : result;
            if (result) {
              resolve(result);
            } else {
              reject("Trabajador no encontrado");
            }
          });
        })
        .then(async (result) => {
          this.worker.stateErrorWorker = null;
          this.worker.about = result;
          this.initAccidentes(result.id);
        })
        .catch((error) => {
          this.worker.stateErrorWorker = false;
          this.worker.about = null;
          console.error(error);
        })
        .finally(() => {
          this.worker.searching = false;
        });
    },
    async initAccidentes(personaId) {
      let result = await this.$store.dispatch("get", {
        path: `RegistroDeAccidente/PersonaId/${personaId}`,
      });
      for (let accidente of result) {
        await this.initSeguimientosAccidentes(accidente.id, accidente);
      }
      this.accidentes = result;
      // console.log(this.accidentes);
    },
    async initSeguimientosAccidentes(accidenteId, accidente) {
      let result = await this.$store.dispatch("get", {
        path: `RegistroDeAccidenteSeguimiento/RegistroDeAccidenteId/${accidenteId}`,
      });
      accidente.seguimientos = result;
    },
    async initPeligro(peligroId) {
      let result = await this.$store.dispatch("get", {
        path: `RegistroDeAccidenteSeguimiento/RegistroDeAccidenteId/${peligroId}`,
      });
      return result;
    },
    createForm() {
      this.hasForm = true;
      this.initNewForm();

      this.initnewSeguimientoForm();
    },
    cancelForm() {
      this.hasForm = false;
    },
    async handleForm() {
      // console.log("formulario accidente", this.newForm);
      try {
        // crea una copia del objeto
        const registroAccidentePost = Object.assign({}, this.newForm);

        // asignar los seguimientos en una nueva variable
        const registroSeguimientoDeAccidentesPost =
          registroAccidentePost.seguimientosDeAccidentes;

        // eliminar los seguimientos de los registros de accidente
        delete registroAccidentePost.seguimientosDeAccidentes;

        // asignar id de la persona
        registroAccidentePost.personaId = this.worker.about.id;

        // enviar registro de accidente a BD (retorna el id del registro)
        const registroDeAccidenteId = await this.RegistroAccidentePost(
          registroAccidentePost
        );

        for (let seguimiento of registroSeguimientoDeAccidentesPost) {
          // asignar id de registro de accidente a el seguimiento
          seguimiento.registroDeAccidenteId = registroDeAccidenteId;

          // enviar registro de seguimiento
          await this.RegistroSeguimientoDeAccidentesPost(seguimiento);
        }

        // alerta de mensaje
        Swal.fire(
          "Perfecto!",
          "Se ha enviado la información correctamente.",
          "success"
        );

        this.hasForm = false;
      } catch (error) {
        Swal.fire(
          "Error!",
          "Ha ocurrido un error al enviar la información.",
          "error"
        );
      }
    },
    async RegistroAccidentePost(data) {
      try {
        const res = await this.$store.dispatch("post", {
          path: `RegistroDeAccidente/`,
          data,
        });
        return res; // id del registro
      } catch (error) {
        return error;
      }
    },
    async RegistroSeguimientoDeAccidentesPost(data) {
      try {
        const res = await this.$store.dispatch("post", {
          path: `RegistroDeAccidenteSeguimiento/`,
          data,
        });
        return res; // id del registro
      } catch (error) {
        return error;
      }
    },
    ShowFormNewSeg(accidenteId) {
      this.addNewSeguimiento.id = accidenteId;
      this.addNewSeguimiento.isActive = true;
      this.accidenteActivo.seguimientoActivo.data = [];
    },
    CloseFormSeg() {
      this.addNewSeguimiento.isActive = null;
    },
    async SendNewSeg() {
      // asignamos el id del accidente
      this.addNewSeguimiento.formData.registroDeAccidenteId =
        this.addNewSeguimiento.id;
      try {
        // enviar registro de seguimiento
        await this.RegistroSeguimientoDeAccidentesPost(
          this.addNewSeguimiento.formData
        );

        // caso correcto
        Swal.fire(
          "Perfecto!",
          "Se ha enviado la información correctamente.",
          "success"
        );

        // reseteamos los valores del formulario
        this.initAddNewSeguimientoForm();

        // cerramos formulario
        this.CloseFormSeg();
      } catch (error) {
        // caso de error
        Swal.fire(
          "Error!",
          "Ha ocurrido un error al enviar la información.",
          "error"
        );
      }
    },
    KeyDownInputSearch() {
      this.worker.stateErrorWorker = null;
    },
    initnewSeguimientoForm() {
      this.newSeguimientoForm = {
        fechaDeEjecucionEsperada: null,
        fechaDeSeguimiento: null,
        planDeAccionEjecutado: null,
        evidencias: null,
        observaciones: null,
        responsable: null,
      };
    },
    initAddNewSeguimientoForm() {
      this.addNewSeguimiento.formData = {
        fechaDeEjecucionEsperada: null,
        fechaDeSeguimiento: null,
        planDeAccionEjecutado: null,
        evidencias: null,
        observaciones: null,
        responsable: null,
      };
    },
    handleFormSeguimientosDeAccidentes() {
      this.newForm.seguimientosDeAccidentes.push(this.newSeguimientoForm);
      this.initnewSeguimientoForm();
    },
    AccidentSelected(id, index) {
      let result = this.accidentes.filter((accidente) => accidente.id === id);
      result[0].index = index;
      this.accidenteActivo.data = result;
      this.accidenteActivo.seguimientoActivo.data = [];
    },
    SeguimientoSelected(id, index) {
      let result = this.accidenteActivo.data[0].seguimientos.filter(
        (seguimiento) => seguimiento.id === id
      );
      result[0].index = index;
      this.accidenteActivo.seguimientoActivo.data = result;
    },
  },
  filters: {
    formatDate(value) {
      //formate fecha de la forma 2020-12-31T00:00:00 a 31/12/2020
      return value?.split("T")[0].split("-").reverse().join("/");
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="text-white"><b> Registro de accidentes</b></h4>
          </template>
          <div class="card-body">
            <h4 class="card-title text-white mb-3"><b>Trabajador</b></h4>

            <b-form @submit.prevent="searchingWorker">
              <!-- Busqueda por CC, nombre y apellidos -->
              <b-form-group label-for="imputSearchWorker" label="Cédula del trabajador"
                invalid-feedback="Trabajador no encontrado" description="Cédula de ciudadanía / Nombres y apellidos"
                :state="worker.stateErrorWorker">
                <b-form-input id="imputSearchWorker" v-model="worker.imputSearchWorker" :state="worker.stateErrorWorker"
                  @keydown="KeyDownInputSearch" type="text" placeholder="Agregar informacion de busqueda"
                  required></b-form-input>
              </b-form-group>
              <b-button type="submit" variant="outline-info" :disabled="worker.searching"><i class="fas fa-search"></i>
                Buscar</b-button>
            </b-form>
            <!-- Si existe el trabajador se mostrara la siguiente informacion -->
            <article v-if="worker.about" class="mt-4">
              <h4 class="my-3">Información del trabajador</h4>
              <b-list-group class="info-worker my-3">
                <b-list-group-item class="flex-column align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h4 class="mb-1">
                      {{ worker.about.apellidos }} {{ worker.about.nombres }}
                    </h4>
                  </div>
                  <p>
                    <strong>Cédula de ciudadanía: </strong>{{ worker.about.numeroDocumento }}
                  </p>
                  <p>
                    <strong>Número de accidentes laborales: </strong><span
                      class="py-1 px-2 border rounded font-weight-bold">{{
                        accidentes.length || 0
                      }}</span>
                  </p>
                </b-list-group-item>
              </b-list-group>

              <div class="mt-4" v-if="accidentes.length">
                <h4 class="mb-3">Registros de accidentes</h4>

                <!-- listado de accidentes -->
                <div class="d-flex flex-wrap mb-2">
                  <b-list-group class="btn" v-for="(accidente, index) in accidentes" :key="accidente.id"
                    @click="AccidentSelected(accidente.id, index)">
                    <b-list-group-item>
                      <p>{{ accidente.fechaDelAccidente | formatDate }}</p>
                      <p class="m-0">
                        Accidente
                        <span class="py-1 px-2 bg-blue rounded">
                          {{ index + 1 }}
                        </span>
                      </p>
                    </b-list-group-item>
                  </b-list-group>
                </div>

                <!-- informacion registros de accidentes -->
                <b-list-group>
                  <b-list-group-item style="
                      display: grid;
                      grid-template-columns: 1fr 1fr;
                      grid-gap: 10px;
                    " v-for="accidente in accidenteActivo.data" :key="accidente.id">
                    <div>
                      <h4 class="mb-3">
                        Accidente ~
                        <span class="py-1 px-2 border rounded font-weight-bold">{{ accidente.index + 1 }}</span>
                      </h4>

                      <p>
                        <strong>Fecha de accidente:</strong>
                        {{ accidente.fechaDelAccidente | formatDate }}
                      </p>
                      <p>
                        <strong>Tipo de evento:</strong>
                        {{ accidente.tipoDeEvento }}
                      </p>
                      <p>
                        <strong>Lugar de accidente:</strong>
                        {{ accidente.lugarDelAccidente }}
                      </p>
                      <p>
                        <strong>Descripcion del accidente:</strong>
                        {{ accidente.descripcion }}
                      </p>
                      <p>
                        <strong>Origen del peligro:</strong>
                        {{ accidente.peligro }}
                      </p>
                      <p>
                        <strong>Tipo de evento especifico:</strong>
                        {{ accidente.tipoDeEventoEspecifico }}
                      </p>
                      <p>
                        <strong>Tipo de lesiòn:</strong>
                        {{ accidente.tipoDeLesion }}
                      </p>
                      <p>
                        <strong>Agente del accidente:</strong>
                        {{ accidente.agenteDelAccidente }}
                      </p>
                      <p>
                        <strong>Parte del cuerpo afectada:</strong>
                        {{ accidente.parteDelCuerpoAfectada }}
                      </p>
                      <p>
                        <strong>Accidente investigado:</strong>
                        {{ accidente.accidenteInvestigado }}
                      </p>
                      <p>
                        <strong>Investigación en viada a la ARL:</strong>
                        {{ accidente.accidenteInvestigado }}
                      </p>
                      <p>
                        <strong>Causas inmediatas:</strong>
                        {{ accidente.causasInmediatas }}
                      </p>
                      <p>
                        <strong>Causas básicas:</strong>
                        {{ accidente.causasBasicas }}
                      </p>
                      <p>
                        <strong>Acciones a implementar:</strong>
                        {{ accidente.accionesAImplementar }}
                      </p>
                    </div>

                    <div>
                      <div class="d-flex align-items-center ml-3 mt-4 mb-2 flex-wrap">
                        <h4>Registros de seguimientos del accidente</h4>
                        <span v-b-modal.addSeg @click="ShowFormNewSeg(accidente.id)"
                          class="btn btn-outline-success px-5 my-1 ml-2 font-weight-bold">Agregar nuevo</span>
                      </div>
                      <!-- formulario para agragar los seguimientos -->
                      <b-list-group v-if="addNewSeguimiento.isActive" class="my-3" style="grid-column: 1 / 4">
                        <b-list-group-item>
                          <div class="d-flex align-items-center justify-content-between">
                            <h4 class="text-white mb-3">
                              Agregar datos de seguimiento
                            </h4>
                            <span @click="CloseFormSeg" class="btn text-secondary">Cerrar</span>
                          </div>
                          <b-form style="
                              display: grid;
                              grid-template-columns: 1fr 1fr 1fr;
                              grid-gap: 10px;
                            " @submit.prevent="SendNewSeg" class="my-4 text-truncate">
                            <div>
                              <b-form-group label-for="responsable" label="Responsable">
                                <b-form-input id="responsable" type="text" v-model="addNewSeguimiento.formData.responsable
                                  " placeholder="Agregar responsable" required>
                                </b-form-input>
                              </b-form-group>
                            </div>
                            <div>
                              <b-form-group label-for="fechaDeEjecucionEsperada" label="Fecha de ejecución esperada">
                                <b-form-input type="date" id="fechaDeEjecucionEsperada" v-model="addNewSeguimiento.formData
                                  .fechaDeEjecucionEsperada
                                  " required></b-form-input>
                              </b-form-group>
                            </div>
                            <div>
                              <b-form-group label-for="fechaDeSeguimiento" label="Fecha de seguimiento">
                                <b-form-input type="date" id="fechaDeSeguimiento" v-model="addNewSeguimiento.formData
                                  .fechaDeSeguimiento
                                  " required></b-form-input>
                              </b-form-group>
                            </div>
                            <div>
                              <b-form-group label-for="planDeAccionEjecutado" label="Plan de acción ejecutado">
                                <b-form-textarea id="planDeAccionEjecutado" v-model="addNewSeguimiento.formData
                                  .planDeAccionEjecutado
                                  " placeholder="Agregar plan de acción ejecutado" rows="3" max-rows="6"
                                  required></b-form-textarea>
                              </b-form-group>
                            </div>
                            <div>
                              <b-form-group label-for="evidencias" label="Evidencias">
                                <b-form-textarea id="evidencias" v-model="addNewSeguimiento.formData.evidencias
                                  " placeholder="Agregar evidencias" rows="3" max-rows="6" required></b-form-textarea>
                              </b-form-group>
                            </div>
                            <div>
                              <b-form-group label-for="observaciones" label="Observaciones">
                                <b-form-textarea id="observaciones" v-model="addNewSeguimiento.formData.observaciones
                                  " placeholder="Agregar observaciones" rows="3" max-rows="6"
                                  required></b-form-textarea>
                              </b-form-group>
                            </div>
                            <b-button type="submit" variant="info">Agregar seguimiento</b-button>
                          </b-form>
                        </b-list-group-item>
                      </b-list-group>

                      <!-- listado de seguimientos -->
                      <div v-if="!addNewSeguimiento.isActive" class="d-flex flex-wrap">
                        <b-list-group class="btn" v-for="(seguimiento, index) in accidenteActivo.data[0]
                          .seguimientos" :key="seguimiento.id" @click="SeguimientoSelected(seguimiento.id, index)">
                          <b-list-group-item>
                            <p>
                              {{ seguimiento.fechaDeSeguimiento | formatDate }}
                            </p>
                            <p class="m-0">
                              Seguimiento de accidente
                              <span class="py-1 px-2 bg-blue rounded">
                                {{ index + 1 }}
                              </span>
                            </p>
                          </b-list-group-item>
                        </b-list-group>
                      </div>

                      <!--registro de seguimientos de accidentes -->
                      <div v-for="seguimiento in accidenteActivo.seguimientoActivo
                        .data" :key="seguimiento.id" class="px-5 py-4">
                        <h4 class="mb-3">
                          Seguimiento ~
                          <span class="py-1 px-2 border rounded font-weight-bold">{{ seguimiento.index + 1 }}</span>
                        </h4>
                        <p>
                          <strong>Responsable:</strong>
                          {{ seguimiento.responsable }}
                        </p>
                        <p>
                          <strong>Fecha de seguimiento:</strong>
                          {{ seguimiento.fechaDeSeguimiento | formatDate }}
                        </p>
                        <p>
                          <strong>Fecha de ejecución esperada:</strong>
                          {{
                            seguimiento.fechaDeEjecucionEsperada | formatDate
                          }}
                        </p>
                        <p>
                          <strong>Plan de acción ejecutado:</strong>
                          {{ seguimiento.planDeAccionEjecutado }}
                        </p>
                        <p>
                          <strong>Evidencias:</strong>
                          {{ seguimiento.evidencias }}
                        </p>
                        <p>
                          <strong>Observaciones:</strong>
                          {{ seguimiento.observaciones }}
                        </p>
                      </div>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </article>
          </div>
        </card>

        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-white"><b>Registrar accidente</b></h4>
          </template>
          <div class="card-body">
            <article class="nueva-incapacidad">
              <b-button v-show="!hasForm" @click="createForm" :disabled="!worker.about" block variant="success">Agregar
                nueva enfermedad laboral</b-button>
              <b-button v-show="hasForm" @click="cancelForm" block variant="outline-danger">Cancelar</b-button>
            </article>
            <b-form style="
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 10px;
              " v-if="hasForm" @submit.prevent="handleForm" class="my-4">
              <div>
                <b-form-group label-for="fechaDelAccidente" label="Fecha del accidente">
                  <b-form-input type="date" id="fechaDelAccidente" v-model="newForm.fechaDelAccidente"
                    required></b-form-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="tipoDeEvento" label="Tipo de evento">
                  <b-form-select id="tipoDeEvento" v-model="newForm.tipoDeEvento" :options="formDataBase.tipoDeEvento"
                    required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="lugarDelAccidente" label="Lugar del accidente">
                  <b-form-select id="lugarDelAccidente" v-model="newForm.lugarDelAccidente"
                    :options="formDataBase.lugarDelAccidente" required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="descripcion" label="Descripción del accidente">
                  <b-form-textarea id="descripcion" v-model="newForm.descripcion" placeholder="Agregar descripción"
                    rows="3" max-rows="6" required></b-form-textarea>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="peligro" label="Origen del peligro">
                  <b-form-select id="peligro" v-model="newForm.peligro" :options="formDataBase.peligros"
                    required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="tipoDeEventoEspecifico" label="Tipo de evento especifico">
                  <b-form-select id="tipoDeEventoEspecifico" v-model="newForm.tipoDeEventoEspecifico"
                    :options="formDataBase.tiposDeEventoEspecificos" required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="tipoDeLesion" label="Tipo de lesión">
                  <b-form-select id="tipoDeLesion" v-model="newForm.tipoDeLesion" :options="formDataBase.tiposDeLesiones"
                    required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="agenteDelAccidente" label="Agente del accidente">
                  <b-form-select id="agenteDelAccidente" v-model="newForm.agenteDelAccidente"
                    :options="formDataBase.agentesDelAccidente" required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="parteDelCuerpoAfectada" label="Parte del cuerpo afectada">
                  <b-form-select id="parteDelCuerpoAfectada" v-model="newForm.parteDelCuerpoAfectada"
                    :options="formDataBase.partesDelCuerpoAfectada" required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="accidenteInvestigado" label="Accidente investigado">
                  <b-form-select id="accidenteInvestigado" v-model="newForm.accidenteInvestigado"
                    :options="formDataBase.booleanSelect" required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="investigacionEnviadaALaARL" label="Investigación en viada a la ARL">
                  <b-form-select id="investigacionEnviadaALaARL" v-model="newForm.investigacionEnviadaALaARL"
                    :options="formDataBase.booleanSelect" required></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="causasInmediatas" label="Causas inmediatas">
                  <b-form-textarea id="causasInmediatas" v-model="newForm.causasInmediatas"
                    placeholder="Agregar descripción" rows="3" max-rows="6" required></b-form-textarea>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="causasBasicas" label="Causas básicas">
                  <b-form-textarea id="causasBasicas" v-model="newForm.causasBasicas" placeholder="Agregar descripción"
                    rows="3" max-rows="6" required></b-form-textarea>
                </b-form-group>
              </div>
              <div>
                <b-form-group label-for="AccionesAImplementar" label="Acciones a implementar">
                  <b-form-textarea id="AccionesAImplementar" v-model="newForm.accionesAImplementar"
                    placeholder="Agregar descripción" rows="3" max-rows="6" required></b-form-textarea>
                </b-form-group>
              </div>
              <!-- mostrar los seguimientos agregados -->
              <div style="grid-column: 1 / 4" v-if="newForm.seguimientosDeAccidentes.length" class="my-4">
                <h4 class="text-white my-3">Seguimiento de accidentes</h4>
                <b-list-group style="
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 10px;
                  ">
                  <b-list-group-item v-for="(
                      seguimiento, index
                    ) in newForm.seguimientosDeAccidentes" :key="index" class="px-5 py-4">
                    <h4>Registro de seguimiento - {{ index + 1 }}</h4>
                    <p>
                      <strong>Responsable:</strong>
                      {{ seguimiento.responsable }}
                    </p>
                    <p>
                      <strong>Fecha de seguimiento:</strong>
                      {{ seguimiento.fechaDeSeguimiento }}
                    </p>
                    <p>
                      <strong>Fecha de ejecución esperada:</strong>
                      {{ seguimiento.fechaDeEjecucionEsperada }}
                    </p>
                    <p>
                      <strong>Plan de acción ejecutado:</strong>
                      {{ seguimiento.planDeAccionEjecutado }}
                    </p>
                    <p>
                      <strong>Evidencias:</strong> {{ seguimiento.evidencias }}
                    </p>
                    <p>
                      <strong>Observaciones:</strong>
                      {{ seguimiento.observaciones }}
                    </p>
                  </b-list-group-item>
                </b-list-group>
              </div>

              <!-- formulario para agragar los seguimientos -->
              <b-list-group class="my-3" style="grid-column: 1 / 4">
                <b-list-group-item>
                  <h4 class="text-white mb-3">Agregar datos de seguimiento</h4>
                  <b-form style="
                      display: grid;
                      grid-template-columns: 1fr 1fr 1fr;
                      grid-gap: 10px;
                    " @submit.prevent="handleFormSeguimientosDeAccidentes" class="my-4">
                    <div>
                      <b-form-group label-for="responsable" label="Responsable">
                        <b-form-input id="responsable" type="text" v-model="newSeguimientoForm.responsable"
                          placeholder="Agregar responsable" required>
                        </b-form-input>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group label-for="fechaDeEjecucionEsperada" label="Fecha de ejecución esperada">
                        <b-form-input type="date" id="fechaDeEjecucionEsperada"
                          v-model="newSeguimientoForm.fechaDeEjecucionEsperada" required></b-form-input>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group label-for="fechaDeSeguimiento" label="Fecha de seguimiento">
                        <b-form-input type="date" id="fechaDeSeguimiento" v-model="newSeguimientoForm.fechaDeSeguimiento"
                          required></b-form-input>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group label-for="planDeAccionEjecutado" label="Plan de acción ejecutado">
                        <b-form-textarea id="planDeAccionEjecutado" v-model="newSeguimientoForm.planDeAccionEjecutado"
                          placeholder="Agregar plan de acción ejecutado" rows="3" max-rows="6" required></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group label-for="evidencias" label="Evidencias">
                        <b-form-textarea id="evidencias" v-model="newSeguimientoForm.evidencias"
                          placeholder="Agregar evidencias" rows="3" max-rows="6" required></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div>
                      <b-form-group label-for="observaciones" label="Observaciones">
                        <b-form-textarea id="observaciones" v-model="newSeguimientoForm.observaciones"
                          placeholder="Agregar observaciones" rows="3" max-rows="6" required></b-form-textarea>
                      </b-form-group>
                    </div>
                    <b-button type="submit" variant="outline-primary">Agregar seguimiento</b-button>
                  </b-form>
                </b-list-group-item>
              </b-list-group>

              <b-button type="submit" variant="info">Enviar formulario</b-button>
            </b-form>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
