export default {
  tipoDeEvento: [
    { value: null, text: "Seleccionar" },
    { value: "IT", text: "IT" },
    { value: "AT", text: "AT" },
  ],
  lugarDelAccidente: [
    { value: null, text: "Seleccionar" },
    { value: "Vivienda", text: "Vivienda" },
    { value: "Fuera de la vivienda", text: "Fuera de la vivienda" },
    { value: "Vía pública", text: "Vía pública" },
    { value: "Instalaciones de trabajo", text: "Instalaciones de trabajo" },
  ],
  peligros: [
    { value: null, text: "Seleccionar" },
    { value: "Mecánico", text: "Mecánico" },
    { value: "Eléctrico", text: "Eléctrico" },
    { value: "Locativo", text: "Locativo" },
    {
      value: "Antropico o de orden público",
      text: "Antropico o de orden público",
    },
    { value: "Radioaciones no ionizantes", text: "Radioaciones no ionizantes" },
    { value: "Biológico", text: "Biológico" },
    { value: "Biomecánico", text: "Biomecánico" },
    { value: "Psicosocial", text: "Psicosocial" },
  ],
  tiposDeEventoEspecificos: [
    { value: null, text: "Seleccionar" },
    { value: "Caída de personas", text: "Caída de personas." },
    { value: "Caída de objetos", text: "Caída de objetos." },
    { value: "Pisadas, choques, golpes", text: "Pisadas, choques, golpes." },
    { value: "Atrapamientos", text: "Atrapamientos." },
    {
      value: "Sobreesfuerzo, esfuerzo excesivo o falso movimiento",
      text: "Sobreesfuerzo, esfuerzo excesivo o falso movimiento.",
    },
    {
      value: "Exposición o contacto con temperatura extrema",
      text: "Exposición o contacto con temperatura extrema.",
    },
    {
      value: "Exposición o caontacto con la electricidad",
      text: "Exposición o caontacto con la electricidad.",
    },
    {
      value:
        "Exposición o contacto con sustancias nocivas, radiaciones o salpicaduras",
      text: "Exposición o contacto con sustancias nocivas, radiaciones o salpicaduras. ",
    },
    {
      value: "Contacto con elementos cortopunzantes",
      text: "Contacto con elementos cortopunzantes. ",
    },
    { value: "Proyección de particulas", text: "Proyección de particulas." },
    {
      value: "Macroorganismos <pisadas, mordedura, picaduras>",
      text: "Macroorganismos <pisadas, mordedura, picaduras>.",
    },
    { value: "Otro", text: "Otro." },
  ],
  tiposDeLesiones: [
    { value: null, text: "Seleccionar" },
    { value: "Fractura", text: "Fractura." },
    { value: "Luxación", text: "Luxación." },
    {
      value:
        "Torcedura, esguince, desgarro muscular, hernia o laceración de músculo o tendón sin herida",
      text: "Torcedura, esguince, desgarro muscular, hernia o laceración de músculo o tendón sin herida.",
    },
    {
      value: "Conmoción o trauma interno",
      text: "Conmoción o trauma interno.",
    },
    {
      value: "Amputación o enucleación (exlusión o pérdida del ojo)",
      text: "Amputación o enucleación (exlusión o pérdida del ojo).",
    },
    { value: "Herida", text: "Herida." },
    {
      value:
        "Trauma superficial (incluye rasguño, punción o pinchazo y lesión en ojo por cuerpo extraño)",
      text: "Trauma superficial (incluye rasguño, punción o pinchazo y lesión en ojo por cuerpo extraño).",
    },
    {
      value: "Golpe, contusión o aplastamiento",
      text: "Golpe, contusión o aplastamiento.",
    },
    { value: "Quemadura", text: "Quemadura." },
    {
      value: "Envenenamiento o intoxicación aguda o alergia",
      text: "Envenenamiento o intoxicación aguda o alergia.",
    },
    {
      value: "Efecto del tiempo, del clima u otro relacionado con el ambiente",
      text: "Efecto del tiempo, del clima u otro relacionado con el ambiente.",
    },
    { value: "Asfixia", text: "Asfixia." },
    { value: "Efecto de la electricidad", text: "Efecto de la electricidad." },
    {
      value: "Efecto nocivo de la radiación",
      text: "Efecto nocivo de la radiación.",
    },
    { value: "Lesiones múltiples", text: "Lesiones múltiples." },
    { value: "Otro. (especifique)", text: "Otro. (especifique)" },
    { value: "No aplica", text: "No aplica." },
  ],
  agentesDelAccidente: [
    { value: null, text: "Seleccionar" },
    { value: "Máquinas y/o equipos", text: "Máquinas y/o equipos." },
    { value: "Medios de transporte", text: "Medios de transporte." },
    { value: "Aparatos", text: "Aparatos." },
    {
      value: "Herramientas, implementos o utensilios",
      text: "Herramientas, implementos o utensilios.",
    },
    { value: "Materiales o sustancias", text: "Materiales o sustancias." },
    { value: "Radiaciones", text: "Radiaciones." },
    {
      value:
        "Ambiente de trabajo (incluye superficies de tránsito y de trabajo, muebles, tejados, en el exterior, interior o subterráneos)",
      text: "Ambiente de trabajo (incluye superficies de tránsito y de trabajo, muebles, tejados, en el exterior, interior o subterráneos).",
    },
    {
      value: "Otros agentes no clasificados",
      text: "Otros agentes no clasificados.",
    },
    {
      value: "Animales (vivos o productos animales)",
      text: "Animales (vivos o productos animales).",
    },
    {
      value: "Agentes no clasificados por falta de datos",
      text: "Agentes no clasificados por falta de datos.",
    },
  ],
  partesDelCuerpoAfectada: [
    { value: null, text: "Seleccionar" },
    { value: "Cabeza", text: "Cabeza." },
    { value: "Ojo", text: "Ojo." },
    { value: "Cuello", text: "Cuello." },
    {
      value:
        "Tronco (incluye espalda, columna vertebral, médula espinal, pélvis)",
      text: "Tronco (incluye espalda, columna vertebral, médula espinal, pélvis).",
    },
    { value: "Tórax", text: "Tórax." },
    { value: "Abdomen", text: "Abdomen." },
    { value: "Miembros superiores", text: "Miembros superiores." },
    { value: "Manos", text: "Manos." },
    { value: "Miembros inferiores", text: "Miembros inferiores." },
    { value: "Pies", text: "Pies." },
    { value: "Ubicaciones múltiples", text: "Ubicaciones múltiples." },
    {
      value: "Lesiones generales u otras",
      text: "Lesiones generales u otras.",
    },
  ],
  booleanSelect: [
    { value: null, text: "Seleccionar" },
    { value: "Si", text: "Si" },
    { value: "No", text: "No" },
  ],
};
